.div-tes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: #ffffff;
  text-align: center;
  margin-bottom: 50px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
  padding: 6%;
  padding-bottom: 10%;
}
.div-1:hover{
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  transition: all 0.3s ease-in-out;
  /* cursor: pointer; */
}
.ta-text{
  color: #999999;
  font-size: 1.1rem;
  
}
.ta-heading{
  padding-top: 5px;
  color: #13131A;
  font-weight: 600;
  font-size: 1.3rem;
  padding-top:5%;
  padding-bottom :10%
}
.ta-subHeading{
  color: #999999;
  
}
.tes-testimonal{
  color: #999999;
  font-family: "Manrope-Regular", Helvetica;
  font-family: "Manrope-Regular", Helvetica;
  padding-top: 8%;
  padding-inline: 2%;
}