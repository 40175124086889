* { box-sizing: border-box; }

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow-y: hidden;
}

#root {
  height: 100%;
  font-family: 'Manrope', sans-serif; 
}

.full-width { width: 100%; }

.full-size { width: 100%; height: 100%; }

/* width */
/* ::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

/* Scrollbar styles */
::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background-color: #f3f4f6;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.MuiAccordion-root{
  padding: 0;
}
.MuiPaper-elevation1 {
  box-shadow: none;
}